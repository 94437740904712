import * as React from "react";

import Layout from "../components/layout";
import Room from "../components/room";

import "../styles/global.scss";

const rooms = [
  {
    room: "kennedy",
    name: "The Kennedy Suite",
    desk: true,
    description:
      "Stay in a suite fit for a president. The international study room styled bedroom is fitted with English furniture and a custom leather headboard. The indulgence continues in the high-end, Hollywood regency bathroom with a gold-wrapped bathtub and a foil-decaled accent wall.",
  },
  {
    room: "champagne",
    name: "The Champagne",
    type: "Double Queen",
    guests: "4",
    beds: "2 Queen Beds",
    desk: true,
    description:
      "The Champagne Room is a baroque, continental-style room featuring two French-style queen beds. The blend of silver and gold, original artwork, and modern art-deco chandelier make any stay in this room feel elegant. ",
  },
  {
    room: "green",
    name: "The Green Suite",
    desk: true,
    description:
      "The Green Suite is named for The Edward’s architect, E.B. Green, and takes inspiration from his grand designs. This cozy space offers a seating area, custom wet bar, and French inlaid desk. Guests can end their night by an original E.B. Green fireplace before retiring to the regal four-poster bed.",
  },
  {
    room: "plum",
    name: "The Plum",
    desk: true,
    description:
      "The luxe and bohemian Plum Suite is for those guests who enjoy a touch of drama. The jewel-toned suite based on a New York City penthouse provides a decadent, moody environment. Book soon, the bold colors and textures make the Plum Suite one of our most popular stays.",
  },
  {
    room: "madison",
    name: "The Madison",
    desk: true,
    description:
      "The Madison is a stately and sophisticated blend of French Metropolitan style, boasting a custom silver-leaf ceiling, a restored E.B. Green original fireplace, and a king size bed.",
  },
  {
    room: "deco",
    name: "The Deco",
    description:
      "The Deco Room gets its name from the predominant art style of the 1920s and 30s and, like its namesake, boasts strong lines and colors. The room features acrylic bedposts, bold accents and our boldest bathroom design. The mood of the deco room is vibrant, yet sophisticated.",
  },
  {
    room: "alexander",
    name: "The Alexander",
    description:
      "The Alexander room is a symphony of champagne, silver, and gold tones, giving it a soothing and luxurious feel. The custom, king sized sleigh bed is perfect for sinking into and the bathroom features an open floor-to-ceiling marble shower.",
  },
  {
    room: "puritan",
    name: "The Puritan",
    description:
      "The Puritan room is for the guest that needs to get away from it all. The “pureness” of the room  summons calmness, balance, and zen. The far-east platform bed, parchment paper window treatments and luxurious Mayan-styled vanity make it easy to tune out the world and relax.",
  },
  {
    room: "chelsea",
    name: "The Chelsea",
    description:
      "The Chelsea is our most affordable room but claims all the same luxuries and comforts as the rest of The Edward. Sink into the plush king size bed, enjoy the custom-made malachite furnishings and enjoy the custom tiling in the stand-up shower with space for two.",
  },
];

const RoomsPage = () => {
  return (
    <Layout title="Rooms">
      {rooms.map((room) => (
        <Room
          room={room.room}
          name={room.name}
          description={room.description}
          type={room.type}
          guests={room.guests}
          beds={room.beds}
          desk={room.desk}
        />
      ))}
    </Layout>
  );
};

export default RoomsPage;
