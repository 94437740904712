import React, { useState } from "react";
import "react-dates/initialize";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Icon from "../icon";
import RoomFinder from "../room-finder";

import "./style.scss";

const Room = (props) => {
  const amenities = [
    {
      name: "free-parking",
      text: "Free Parking",
    },
    {
      name: "no-smoking",
      text: "No Smoking",
    },

    {
      name: "internet-access",
      text: "Internet Access",
    },
    {
      name: "wifi",
      text: "Wi-Fi",
    },
    {
      name: "ac",
      text: "Air conditioning",
    },
    {
      name: "heat",
      text: "Heating",
    },
    {
      name: "shower",
      text: "Shower",
    },
    {
      name: "hair-dryer",
      text: "Hairdryer",
    },
    {
      name: "towel",
      text: "Linen & Towels",
    },
    {
      name: "bottle",
      text: "Toiletries",
    },
    {
      name: "steam",
      text: "Steamer",
    },
    {
      name: "coffee",
      text: "Keurig Coffee Maker",
    },
    {
      name: "mini-fridge",
      text: "Mini-Fridge",
    },
    {
      name: "smart-tv",
      text: "Smart TV",
    },
  ];

  const [index, setIndex] = useState(0);
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "rooms" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 1400)
              }
            }
          }
        }
      }
    `
  );

  const filter = allFile.edges.filter((room) =>
    room.node.name.includes(props.room)
  );

  const length = filter.length - 1;

  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1);

  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1);

  const { node } = filter[index];

  const image = getImage(node);

  return (
    <div className="container">
      <div className="room">
        <h1 className="hidden">Rooms</h1>
        <div className="room__slideshow">
          <GatsbyImage
            className="room__image"
            image={image}
            key={node.id}
            alt={node.name.replace(/-/g, " ").substring(2)}
          />
          <div className="room__buttons">
            <button
              className="room__arrow room__arrow-left"
              onClick={() => handlePrevious()}
            >
              <Icon name="chevron-left" size="48px" alt="View Previous Image" />
            </button>
            <button
              className="room__arrow room__arrow-right"
              onClick={() => handleNext()}
            >
              <Icon name="chevron-right" alt="View Next Image" />
            </button>
          </div>
        </div>
        <div className={`room__body room__body--${props.room}`}>
          <div className="room__body-header">
            <div className="room__sub-title">{props.type}</div>
            <h2>{props.name}</h2>
            <hr className="gray" />
          </div>
          <div className="room__room-finder">
            <RoomFinder button="Find Rooms" />
          </div>
          <div className="room__body-left">
            <div className="room__info">
              <div className="room__info-item">
                <Icon name="user" /> Sleeps {props.guests}
              </div>
              <div className="room__info-item">
                <Icon name="bed" /> {props.beds}
              </div>
              <div className="room__info-item">
                <Icon name="sink" /> 1 Bathroom
              </div>
            </div>
            <p>{props.description}</p>
          </div>
          <div className="room__amenities">
            <div class="room__amenities-title">Amenities</div>
            <div className="room__amenities-list">
              {amenities.map((item) => (
                <div className="room__amenity">
                  <Icon name={item.name} />
                  {item.text}
                </div>
              ))}
              {props.desk && (
                <div className="room__amenity room__amenity--desk">
                  <Icon name="desk" />
                  Desk
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Room.defaultProps = {
  guests: "2",
  type: "King",
  beds: "1 King Bed",
};

export default Room;
